@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
}
:root {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 50vh;
}
